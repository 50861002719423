import type { RefObject } from 'react'
import { useEffect } from 'react'

export function useHandleClickOutside(
  ref: RefObject<HTMLElement>,
  onClickOutside: () => void,
  trigger: 'click' | 'mouseup' = 'mouseup'
) {
  useEffect(() => {
    function handleClickOutside(e: MouseEvent | TouchEvent) {
      const isClickOutsideCombobox =
        e.target instanceof Node &&
        !(ref.current?.contains(e.target) || e.target === ref.current)

      if (isClickOutsideCombobox) {
        onClickOutside()
      }
    }

    if (trigger === 'mouseup') {
      // Generally prefer mouseup / touchend events since Headless UI
      // popover elements prevent click events from firing

      window.addEventListener('mouseup', handleClickOutside)
      window.addEventListener('touchend', handleClickOutside)
    } else {
      window.addEventListener('click', handleClickOutside)
    }

    return () => {
      if (trigger === 'mouseup') {
        window.removeEventListener('mouseup', handleClickOutside)
        window.removeEventListener('touchend', handleClickOutside)
      } else {
        window.removeEventListener('click', handleClickOutside)
      }
    }
  }, [onClickOutside, ref, trigger])
}
